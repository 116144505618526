body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn__user {
  font-weight: bold;
  color: #333;
}

.loader {
  position: absolute;
  top: 15rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  background: red;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .loader {
    top: 11.5rem;
  }
}

@media screen and (max-width: 767.98px) {
  .side_bar_btn.d-none {
    display: block !important;
  }
  .sidebar_patch.app-menu.navbar-menu {
    display: none !important;
  }
  [data-layout="vertical"][data-sidebar-size="sm-hover"]
    .navbar-menu
    .navbar-nav
    .nav-link
    span {
    display: block !important;
  }

  [data-layout="vertical"] .navbar-brand-box {
    display: block !important;
  }

  [data-layout="vertical"][data-sidebar-size="sm-hover"] .logo span.logo-lg {
    display: block !important;
  }
  [data-layout="vertical"][data-sidebar-size="sm-hover"] .logo span.logo-sm {
    display: none !important;
  }

  [data-layout="vertical"][data-sidebar-size="sm-hover"] .navbar-menu {
    width: 250px !important;
  }

  [data-layout="vertical"] .app-menu {
    margin-left: 0%;
  }
}
